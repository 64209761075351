<template>
  <div class="resultBox">
    <div class="searchresult">
      <div class="logo">
        <van-icon name="arrow-left" @click="gopage" />
      </div>
      <div class="input">
        <van-field
          left-icon="search"
          v-model="value"
          @focus="gopage"
          placeholder="请输入搜索内容"
        />
      </div>
    </div>
    <div class="content">
      <div class="top">
        <div
          class="classification"
          @click="changeActive(1)"
          :class="active == 1 ? 'active' : ''"
        >
          课程
        </div>
        <!-- <div
          class="classification"
          @click="changeActive(2)"
          :class="active == 2 ? 'active' : ''"
        >
          问答
        </div> -->
      </div>
      <div class="list">
        <div class="item" v-for="(course, key) in list" :key="key" @click="$router.push('/ke/' + course.coursePackageId)">
          <div class="left">
            <img :src="course.coverPath" class="img" />
            <div class="mark">
              {{
                course.isFree == 0
                  ? "免费"
                  : course.isDiscount == 0
                  ? "热卖"
                  : "打折"
              }}
            </div>
          </div>
          <div class="right">
            <div class="title">{{ course.courseName }}</div>
            <div class="text">{{ course.courseSecondName }}</div>
            <div class="mark">
              {{ course.isFree == 0 ? "免费" : "¥" + course.price }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom_text" v-if="!subFlag1">已加载全部页面</div>
  </div>
</template>

<script>
import { theme4, theme11 } from "@/api/home.js";
export default {
  props: {},
  data() {
    return {
      value: "",
      active: 1,
      list: [],
      subFlag1: true,
      subFlag2: true,
      page: 0,
    };
  },
  created() {
    this.getqusList(this.$route.query.search);
    this.page = 0;
    this.subFlag1 = true;
    window.addEventListener("scroll", this.scrollbtm);
  },
  mounted() {
    this.$set(this, "value", this.$route.query.search);
  },
  methods: {
    changeActive(val) {
      this.active = val;
      this.page = 0;
      if (val == 1) {
        this.getqusList(this.$route.query.search);
      } else {
        this.getwdList(this.$route.query.search);
      }
    },
    gopage() {
      this.$router.go(-1);
    },
    scrollbtm() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.subFlag1) {
          this.page += 1;
          if (this.active == 1) {
            this.getqusList(this.$route.query.search);
          } else {
            this.getwdList(this.$route.query.search);
          }
        } else {
          this.$toast("已加载全部数据");
        }
      }
    },
    // 课程
    getqusList(val) {
      let param = new FormData();
      param.append("search", val);
      param.append("page", this.page);
      theme4(param).then((res) => {
        this.list = this.list.concat(res.data.data);
        if (res.data.data.length < 9) {
          this.subFlag1 = false;
        }
      });
    },
    // 问答
    getwdList(val) {
      let param = new FormData();
      param.append("search", val);
      param.append("page", this.page);
      theme11(param).then((res) => {
        this.list = res.data.data;
        if (res.data.data.length < 9) {
          this.subFlag1 = false;
        }
      });
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.resultBox {
  padding: 0 14px 30px;
  .searchresult {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 18px 0 14px 0;

    .logo {
      .van-icon {
        font-size: 26px;
      }
    }
    .input {
      margin: 0 14px;
      width: 95%;
      .van-cell {
        margin: 0 4px;
        background: #f6f7f7;
        border-radius: 14px; /*no*/
      }
    }
  }
  .content {
    .top {
      display: flex;
      border-bottom: 1px solid #dddddd;
      border-top: 1px solid #dddddd;
      padding: 15px 0;
      .classification {
        width: 100%;
        // width: 48%;
        text-align: center;
        font-size: 16px;
        text-align: center;
        height: 20px;
        line-height: 20px;
        &:first-child {
          // border-right: 1px solid #979797; /*no*/
        }
      }
      .active {
        color: #5d7dff;
        &::after {
          border-bottom: 5px solid #5d7dff; /*no*/
          border-radius: 2px; /*no*/
          height: 3px;
          width: 20px;
          display: block;
        }
      }
      .active::after {
        content: "";
        display: block;
        height: 9px;
        width: 25px;
        margin: 0 auto;
      }
    }
    .list {
      .item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 14px 0;
        border-bottom: 1px solid #dddddd;
        // &:last-child {
        //   border: none;
        // }
        .left {
          width: 109px;
          position: relative;
          margin-right: 9px;
          border-radius: 5px; /*no*/
          .img {
            width: 100%;
            border-radius: 5px; /*no*/
          }
          .mark {
            opacity: 0.6;
            position: absolute;
            left: 0;
            top: 0;
            background: #000000;
            border-radius: 5px 0px 5px 0px; /*no*/
            z-index: 9;
            font-size: 11px; /*no*/
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            padding: 2px 4px;
          }
        }
        .right {
          width: calc(100% - 118px);
          .title {
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 550;
            color: #333333;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-wrap: break-word;
          }
          .text {
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-wrap: break-word;
            margin: 6px 0 8px;
          }
          .mark {
            font-size: 13px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 550;
            color: #e13838;
            line-height: 18px;
          }
        }
      }
    }
  }
  .bottom_text {
    text-align: center;
    padding: 20px 0;
  }
}
</style>
